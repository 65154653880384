@import 'navbar';
@import './header';
@import './content';
@import './footer';

//html {
//  scroll-behavior: smooth;
//}

.layout {
  position: relative;
  background-color: #ffffff;
  overflow-x: hidden !important;
  width: 100%;

  &.hamburger-open {
    height: 880px;
    overflow: hidden;
  }

  &.hamburger-close {
    height: 100%;
    overflow: auto;
  }

  .test {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
