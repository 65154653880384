#ranking {
    margin-bottom: 131px;

    .section-title {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: fit-content;
        margin: 0 auto;
        font-size: 48px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        text-align: center;
        color: #00001a;

        span {
            position:relative;
            display:block;
        }

        img {
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: -70px;
        }

        &:after {
            margin: 13px 0 24px;
            background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline.png');
            background-size: 451px 8px;
            content: '';
            width: 451px;
            height: 8px;
        }
    }

    .updated {
        text-align: center;
    }

    .outer-tabs {
        margin-top: 72px;

        .react-tabs__tab {
            border-radius: 4px;
            box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.18);
            background-color: #f9f9f9;

            img {
                margin-right: 16px;
            }
        }

        .react-tabs__tab-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: none;

            li {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 17.3vw;
                height: 62px;
                text-align: center;
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 1.5px;
                color: #000018;
                flex: 1 1 auto;
                margin: 0 12px;
                &:hover {
                    color: #ffffff !important;
                    box-shadow: 0 2px 4px 0 rgba(122, 0, 0, 0.5);
                    background-image: linear-gradient(to bottom, #ff6143, #d91d22);
                    font-weight:600;
                }
                &:first-child {
                    margin-left:0;
                }
                &:last-child {
                    margin-right:0;
                }
            }
        }

        .react-tabs__tab--selected {
            font-size: 20px;
            font-weight: 600 !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.5px;
            color: #ffffff !important;
            box-shadow: 0 2px 4px 0 rgba(122, 0, 0, 0.5);
            background-image: linear-gradient(to bottom, #ff6143, #d91d22);
            border:0;
        }

        .react-tabs__tab-panel {
            margin-top: 48px;
            table { table-layout: fixed; }
            .table-container {
                th {
                    width:82px;
                    &:first-child {
                        text-align: left;
                        width: 25px !important;
                    }
                }
                td {
                    &:first-child {
                        width: 74px;
                        text-align: left;
                    }
                }
                .contestant {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        margin-right: 28px;
                    }
                }
            }

            .event-title {
                margin: 80px auto 24px;
                font-size: 24px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #000018;
            }

            //.flip-countdown {
            //    height: 60px;
            //    display: flex;
            //    justify-content: center;
            //
            //    .flip-countdown-piece {
            //        margin: 0;
            //
            //        &:not(last-of-type) {
            //            display: flex;
            //            justify-content: center;
            //            align-items: center;
            //            flex-direction: row-reverse;
            //
            //            .flip-countdown-title {
            //                margin: 0 24px 0;
            //                display: block;
            //                font-size: 32px;
            //                font-weight: normal;
            //                font-stretch: normal;
            //                font-style: normal;
            //                line-height: 1;
            //                letter-spacing: normal;
            //                text-align: center;
            //                color: #000000;
            //            }
            //        }
            //
            //        &:last-of-type {
            //            .flip-countdown-title {
            //                display: none;
            //            }
            //        }
            //
            //        .flip-countdown-card {
            //            height: 100%;
            //            .flip-countdown-card-sec {
            //                border-radius: 3.5px;
            //                margin: 0 2px !important;
            //                font-size: 42px !important;
            //                font-weight: normal;
            //                font-stretch: normal;
            //                font-style: normal;
            //                letter-spacing: normal;
            //                text-align: center;
            //                color: #000000;
            //
            //                .card__top {
            //                    margin-top: 0.025em;
            //                }
            //
            //                .card__top,
            //                .card__bottom,
            //                .card__back,
            //                .card__back:before,
            //                .card__back:after {
            //                    color: #000000 !important;
            //                }
            //
            //                .card__top,
            //                .card__bottom,
            //                .card__back:before,
            //                .card__back:after {
            //                    height: 0.8em;
            //                }
            //
            //                .card__top {
            //                    line-height: normal;
            //                }
            //
            //                .card__back:before {
            //                    padding: 0.15em 0.1em;
            //                }
            //
            //                span {
            //                    width: 45px;
            //                }
            //            }
            //        }
            //    }
            //}

            .flip-countdown {
                height: 60px;
                display: flex;
                justify-content: center;

                .flip-countdown-piece {
                    margin: 0;

                    &:not(last-of-type) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: row-reverse;

                        .flip-countdown-title {
                            margin: 0 24px 0;
                            display: block;
                            font-size: 32px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000000;
                        }
                    }
                    &:not(:first-child){
                        .flip-countdown-title {
                          margin: 0 24px 11px;
                        }
                      }

                    &:last-of-type {
                        .flip-countdown-title {
                            display: none;
                        }
                    }

                    .flip-countdown-card {
                        height: 100%;
                        .flip-countdown-card-sec {
                            border-radius: 3.5px;
                            margin: 0 2px !important;
                            font-size: 42px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000000;

                            .card__top,
                            .card__bottom,
                            .card__back,
                            .card__back:before,
                            .card__back:after {
                                color: #000000 !important;
                            }

                            .card__top,
                            .card__bottom,
                            .card__back:before,
                            .card__back:after {
                                height: 0.68em;
                            }

                            .card__top {
                                line-height: normal;
                            }

                            .card__back:before {
                                padding: 0.15em 0.1em;
                            }

                            .card__bottom:after {
                                margin-top: -0.54em;
                            }

                            span {
                                width: 45px;
                            }
                        }
                    }
                }
            }

            .disclaimer {
                display: none;
            }
        }
    }

    .inner-tabs {
        .inner-tab-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 10px;

            span {
                position: absolute;
                right: 0;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.14;
                letter-spacing: 1.05px;
                text-align: right;
                color: #696969;
                cursor: pointer;
            }
        }
        .react-tabs__tab {
            padding: 0;
            box-shadow: unset;
            border-color: transparent;
            background-color: transparent;
            background-image: unset;
            border-radius: 0;
        }

        .react-tabs__tab--selected {
            margin-bottom: -2px;
            border-bottom: 2px solid #ec3f33;
            font-weight: bold !important;
            color: #ec3f33 !important;
            border:0;
        }

        .react-tabs__tab-list {
            justify-content: center;
            margin: 0;

            li {
                width: unset;
                &:hover {
                    color: #ec3f33 !important;
                    box-shadow: initial;
                    background-image: initial;
                }

                &:first-of-type {
                    margin-right: 40px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    #ranking {
        .section-title {
            width: 34vw;
            font-size: 30px;
            font-weight: bold;
            color: #000018;

            &:after {
                margin: 13px 0 40px;
            }
        }

        .outer-tabs {
            .react-tabs__tab img {
                display: none;
            }

            .react-tabs__tab-list li {
                height: 36px;
                font-size: 14px;
            }

            .react-tabs__tab-panel {
                .flip-countdown {
                    .flip-countdown-piece {
                        .flip-countdown-card {
                            .flip-countdown-card-sec {
                                font-size: 24px !important;

                                span {
                                    width: 30px;
                                }
                            }
                        }

                        &:not(last-of-type) {
                            .flip-countdown-title {
                                margin: 0 8px 0;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        .inner-tabs {
            .inner-tab-wrapper {
                position: unset;
                display: unset;
                justify-content: unset;
                align-items: unset;

                span {
                    margin: 16px auto 0;
                    position: unset;
                    display: block;
                    text-align: center;
                }
            }
            .react-tabs__tab-list li {
                width: unset;
                font-size: 20px !important;
            }
        }
        .table-container>table {
            min-width: 1200px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    #ranking {
        .flip-countdown {
            height: auto !important;
            .flip-countdown-piece {
                .flip-countdown-card {
                    .flip-countdown-card-sec {
                        font-size: 24px !important;

                        .card__bottom:after {
                            margin-top: -0.52em !important;
                        }

                        span {
                            width: 30px;
                        }
                    }
                }

                &:not(last-of-type) {
                    .flip-countdown-title {
                        margin: 0 8px 0;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #ranking {
        margin-bottom:80px;
        .section-title {
            width: 65vw;
        }
        .outer-tabs {
            .react-tabs__tab-list {
                display: block;
                li {
                    display: inline-block;
                    padding: 8px 20px;
                    margin: 5px;
                    width: 45% !important;
                    height: auto;
                    font-size: 14px;
                }
            }

            //.react-tabs__tab-panel {
            //    .table-container {
            //        table {
            //            thead tr th:nth-child(2), tbody tr td:nth-child(2) {
            //                text-align: left;
            //                width: 20%;
            //            }
            //            thead tr th:nth-child(3), tbody tr td:nth-child(3) {
            //                text-align: left;
            //                width: 10%;
            //            }
            //            thead tr th:nth-child(4), tbody tr td:nth-child(4) {
            //                 width: 12%;
            //            }
            //            thead tr th:nth-child(5), tbody tr td:nth-child(5) {
            //                width: 12%;
            //            }
            //            thead tr th:nth-child(6), tbody tr td:nth-child(6) {
            //                width: 12%;
            //            }
            //            thead tr th:nth-child(7), tbody tr td:nth-child(7) {
            //                width: 12%;
            //            }
            //        }
            //    }
            //}
        }
        .inner-tabs {
            .react-tabs__tab-list {
                display: flex;
            }
        }
        .flip-countdown {
            .flip-countdown-card-sec {
                span {
                    width: 25px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    #ranking {
        .flip-countdown-card-sec {
            span {
                width: 20px !important;
            }
        }
    }
}
