#investment {
  background: black url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/investment-bg.png') no-repeat center top;
  background-size: cover;
  padding: 224px 16px 0px;

  &.kr {
    padding: 224px 16px 183px;
  }

  .container {
    max-width:1398px;
    width:80vw;
    margin:auto;
    &:first-child {
      max-width: 1920px;
      width: 100%;
      padding: 0 354px;
    }
    &.mw1458 {
      max-width: 1458px;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    max-width: 1300px;
    margin: auto;
    padding: 0 16px;

    span {
      font-weight: 300;
    }

    &:after {
      margin: 13px 0 24px;
      background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline-black.svg');
      background-size: 451px 8px;
      content: '';
      width: 451px;
      height: 8px;
    }
  }

  .desc {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 1.5px;
    text-align: left;
    color: #fff;
    max-width: 1300px;
    margin: auto;
    margin-bottom:76px;
  }

  .allContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    max-width: 1300px;
    margin: auto;
  }
  .content {
    max-width:418px;
    img {
      width:60px;
      height:auto;
      margin-bottom:16px;
    }
    h5 {
      margin-bottom:15px;
      font-size: 28px;
      font-weight: bold;
      color: #fff;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1.5px;
      color: #737373;
      min-height:48px;
      margin-bottom:63px;
    }
  }
  .bottom-panel {
    h3 {
      font-size: 48px;
      font-weight: bold;
      letter-spacing: 1.5px;
      color: #fff;
      margin-bottom:147px;
      text-align: center;
      position:relative;
      &:after {
        content:'';
        margin: 13px auto 24px;
        background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline-black.svg');
        background-size: 451px 8px;
        width: 451px;
        height: 8px;
        display:block;
      }
    }
    div {
      padding:55px 88px 50px 746px;
      background:#fff;
      position:relative;
      img {
        position:absolute;
        left: -54px;
        top: -33px;
      }
      h4 {
        font-size: 28px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 1.5px;
        color: #000018;
        margin-bottom:34px;
      }
      p {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 1.5px;
        color: #000018;

        span {
          display: block;
          padding-top: 21px;
        }

        &:last-of-type {
          margin-top: 21px;
          margin-bottom: 64px;
        }
      }
      a {
        cursor:pointer;
        text-decoration: none;
        font-size: 20px;
        letter-spacing: 1.5px;
        color: #fff;
        padding:17px 32px;
        box-shadow: 0 2px 4px 0 rgba(122, 0, 0, 0.5);
        background: #ff6143;
        background: -moz-linear-gradient(top,  #ff6143 0%, #d91d22 100%);
        background: -webkit-linear-gradient(top,  #ff6143 0%,#d91d22 100%);
        background: linear-gradient(to bottom,  #ff6143 0%,#d91d22 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff6143', endColorstr='#d91d22',GradientType=0 );
        display: inline-block;
      }
    }
  }
  .anniversary {
    margin-left: -16px;
    margin-right: -16px;
    position: relative;
    height: 258px;
    background: #fe2a1e;
    img {
      -max-width: 937px;
      width: 100%;
      height: 100%;
      display: block;
      margin: auto;
      object-fit: cover;
    }
    .container {
      position:relative;
      max-width: 1381px;
      padding: 0 15px !important;
      padding-bottom: 0 !important;
    }
    .content {
      position:absolute;
      left:0;
      top:0;
      max-width: 100%;
      width: 100%;
      height: 100%;
      display: flex;
    }
    h5 {
      font-size: 48px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 2px;
      color: #fff;
      margin-bottom:8px;
      text-shadow: 0 2px 9px rgba(129, 0, 0, 0.5);
    }
    p {
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.17px;
      color: #fff;
      margin-bottom:24px;
      text-shadow: 0 2px 9px rgba(129, 0, 0, 0.5);
    }
    a {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
      color: #ec3f33;
      cursor:pointer;
      padding: 9px 47px 12px;
      border-radius: 30px;
      background-image: linear-gradient(105deg, #fff 0%, #e3e3e3 98%);
      display: block;
      width: fit-content;
      text-decoration:none;
    }
  }
}

.investment-carousel-container {
  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      &:last-child button {
        margin-right: 0px;
      }
      button {
        width: 6px;
        height: 6px;
        border-radius: unset;
        border-width: unset;
        border-style: unset;
        border-color: #999;
        background: #999;
        margin-right: 22px;
      }
    }
    .react-multi-carousel-dot--active button {
      border-color: #d91d22;
      background: #d91d22;
    }
  }
}

#investment-panel {
  padding-top:160px;
  margin-bottom: 117px !important;
}

@media only screen and (max-width: 1600px) {
  #investment .container:first-child {
    padding: 0;
  }
}

@media only screen and (max-width: 1280px) {
  #investment .container {
    width: 100%;
  }
}

@media only screen and (max-width: 1199px) {
  #investment {
    padding: 224px 16px 0;

    .bottom-panel div {
      padding: 0 16px 40px;
      img {
        position: relative;
        left: initial;
        display: block;
        margin: auto;
        top: -40px;
        max-width:734px;
        width:100%;
      }
      h4, p {
        max-width:734px;
        width:100%;
        margin:0 auto 34px;
        
      }
      h4 {
        font-size: 20px;
        letter-spacing: 1.5px;
        margin-bottom:24px;
        text-align: left;
      }
      p {
        margin-bottom:40px;
        line-height: 1.5;
        letter-spacing: 1.5px;
        font-size: 16px;
      }
      a {
        border-radius: 4px;
        padding: 7px 40px;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 1.31px;
        width: 142px;
        display: block;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #investment {
    .desktop { display:grid !important}
    .mobile { display:none !important}
  }
}
@media only screen and (max-width: 767px) {
  #investment {
    padding: 40px 0px 0px;
    .desc {
      padding:0 20px;
    }
    

    .mobile { display:flex !important}
    .desktop { display:none !important}
    .title {
      font-size: 30px;
      letter-spacing: 1.5px;
      align-items: center;
      padding-bottom: 58px;
      position: relative;
      &:after {
        margin-bottom: 18px;
        width: 100%;
        background: url(https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline_black.svg) no-repeat center center;
        height: 15px;
        overflow-y: hidden;
        margin-top: 17px;
        content: '';
        position: absolute;
        bottom: 0;
      }
      div {
        text-align: center;
      }
    }
    .desc {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1.5px;
      text-align: center;
      margin-bottom: 64px;
    }
    .investment-custom-dot-list {
      bottom:40px;
    }
    .content {
      max-width: 343px;
      margin: auto;
      text-align: center;
      &:last-child {
        padding-bottom:84px;
        p {
          margin-bottom:0px;
        }
      }
      h5 {
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: 1.5px;
        margin-bottom:16px;
      }
      p {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 1.5px;
        margin-bottom:64px;
      }
    }
    .bottom-panel {
      margin-bottom: 0 !important;
      > div {
        padding-left:16px;
        padding-right:16px;
        text-align: center;
      }
      h3 {
        font-size: 30px;
        letter-spacing: 1.5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        padding-bottom: 58px;
        position: relative;
        &:after {
          overflow-x: hidden;
          margin-bottom: 18px;
          width: 100%;
          background: url(https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline_black.svg) no-repeat center center;
          height: 15px;
          overflow-y: hidden;
          margin-top: 17px;
          content: '';
          position: absolute;
          bottom: 0;
        }
      }
    }
    #investment-panel {
      padding-top: 80px;
    }
    .anniversary {
      .content {
        padding-bottom:initial !important;
      }
      .container {
        a {
          margin: auto;
        }
      }
    }
  }
}
