.about {
  .modal {
    padding: 48px 59px 46px;
    max-width: 831px;

    .popup-wrapper {
      text-align: center;

      .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2a2a2a;

        &:after {
          content: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline.png');
          overflow-x: hidden;
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      p {
        margin: 40px 0 91px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #383838;

        span {
          font-weight: bold;
        }

        br {
          display: block;
          content: '';
          margin-top: 16px;
        }
      }

      .button {
        cursor: pointer;
        display: inline-block;
        margin: 0 auto;
        height: 59px;
        padding: 15px 59px 14px;
        border-radius: 6px;
        background-image: linear-gradient(to right, #ff7116, #d91d22);
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .about {
    .modal {
      height: 80vh;

      .popup-wrapper {
        .title {
          font-size: 20px;
        }

        p {
          margin: 20px 0 20px;
          font-size: 16px;
        }

        .button {
          height: auto;
          font-size: 14px;
        }
      }
    }
  }
}
