#activity {
  margin-bottom: 160px;

  .section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #000018;

    &:after {
      margin: 13px 0 56px;
      background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline.png');
      background-size: 451px 8px;
      content: '';
      width: 451px;
      height: 8px;
    }
  }

  .content {
    .top-panel {
      display: flex;
      justify-content: space-between;
      padding: 0 56px 0 0;
      margin-bottom: 24px;
      box-shadow: 0 2px 9px 0 rgba(52, 52, 52, 0.22);
      //background-image: linear-gradient(102deg, #f7f7f7 0%, #e3e3e3 98%);
      background-color: #f5f8f9;

      .image {
        position: relative;
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          width: 100%;
        }
      }

      .text {
        margin-top: 48px;
        width: 55%;
        position:relative;

        span {
          margin-bottom: 32px;
          font-size: 36px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 2.7px;
          color: #ec3f33;

          &.amount {
            margin-left: 24px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.5px;
            color: #d91d22;
          }

          &.disclaimer {
            display: block;
            cursor: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/tooltip-cursor.png'), auto;
            text-decoration: underline;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: 1.5px;
            text-align: right;
            color: #2a2a2a;
            position: absolute;
            top: -32px;
            right: -38px;
          }
        }

        div:first-of-type {
          margin-bottom: 29px;
          display: block;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: 1.78px;
          color: #212121;
          span {
            font-size: 48px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 4.5px;
            color: #ec3f33;
          }
        }

        .card-button {
          cursor: pointer;
          max-width:150px;
          width:100%;
          padding: 12px 0px;
          border-radius: 4px;
          border: none;
          outline: none;
          box-shadow: 0 2px 4px 0 rgba(104, 0, 0, 0.5);
          background-image: linear-gradient(to bottom, #ff6143, #d91d22);
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 1.5px;
          text-align: center;
          color: #fff;
          margin-bottom: 32px;
        }
      }
    }
    .bottom-panel {
      display: flex;
      justify-content: space-between;

      &.desktop {
        display: flex;
      }

      &.mobile {
        display: none;

        .card:first-of-type {
          .content-wrapper {
            position: relative;
            .image {
              position: absolute;
              bottom: 20%;
            }
            .text {
              position: relative;
              padding-top: 70px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .card:nth-child(2) {
          .content-wrapper {
            display: flex;
            .text {
              width: 50%;
            }
            .image {
              width: 50%;
            }
          }
        }

        .card:nth-child(3) {
          .content-wrapper {
            display: flex;
            .text {
              width: 60%;
            }
            .image {
              width: 40%;
            }
          }
        }
      }

      .card {
        position: relative;
        padding: 20px 48px 20px 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 49%;
        box-shadow: 0 2px 9px 0 rgba(52, 52, 52, 0.22);
        //background-image: linear-gradient(102deg, #f7f7f7 0%, #e3e3e3 98%);
        background-color: #f5f8f9;

        &:last-child {
          .image {
            max-width: 300px;
            width: 100%;
          }
        }

        .text {
          width: 60%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          span {
            &:nth-child(1) {
              margin-bottom: 29px;
              font-size: 28px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 2.1px;
              text-align: center;
              color: #ec3f33;
            }

            &:nth-child(2) {
              margin-bottom: 32px;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: 1.5px;
              color: #2f2f2f;
            }

            &.disclaimer {
              position: absolute;
              top: 9px;
              right: -6px;
              margin-right: 24px;
              margin-bottom: 18px;
              cursor: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/tooltip-cursor.png'), auto;
              text-decoration: underline;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: 1.31px;
              color: #2a2a2a;
            }
          }

          .wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .card-button {
              cursor: pointer;
              max-width:150px;
              width:100%;
              padding: 12px 0px;
              border-radius: 4px;
              border: none;
              outline: none;
              box-shadow: 0 2px 4px 0 rgba(104, 0, 0, 0.5);
              background-image: linear-gradient(to bottom, #ff6143, #d91d22);
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: 1.5px;
              text-align: center;
              color: #fff;
            }

            .amount {
              margin-left: 16px;
              margin-bottom: 0;
              white-space: nowrap;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: 1.5px;
              color: #d91d22;
            }
          }
        }
        .image {
          max-width: 261px;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .__react_component_tooltip {
    width: 40%;

    &.show {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 1599px) {
  #activity .content .bottom-panel.desktop {
    .card {
      .image {
        max-width: 220px;
      }      
      &:last-child {
        .image {
          max-width: 209px;
        }
      }
    }
  }
}


@media only screen and (min-width: 768px) and (max-width: 1380px) {
  #activity {
    .content {
      .top-panel {
        .image {
          img {
            bottom: 20%;
            width: 90%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  #activity {
    .section-title {
      font-size: 30px;
      font-weight: bold;
      color: #000018;

      &:after {
        margin: 13px 0 79px;
      }
    }

    .content .bottom-panel .card .text .wrapper .amount {
       font-size: 16px;
    }
  }
}

@media only screen and (max-width: 991px) {
  #activity .content .bottom-panel.desktop {
    display: block;
    .card {
      width:100%;
      margin-bottom:16px;
    }
  }
}

@media only screen and (max-width: 767px) {
  #activity {
    &.th {
      .content {
        .bottom-panel .card:nth-child(1) .desc {
          font-size: 14px;
        }
      }
    }

    .content {
      .top-panel {
        display: none;
      }
      .bottom-panel {
        &.desktop {
          display: none;
        }

        &.mobile {
          display: block;
        }

        .card {
          margin-bottom: 32px;
          width: 100%;
          height: auto;
          padding: 0;
          display: block;

          &:first-child {
            .content-wrapper .text {
              span:first-child {
                margin-bottom: 16px;
              }
            }
          }

          .content-wrapper {
            width: 100%;
            .text {
              width: auto;
              padding: 32px 15px 0 16px;
              span {
                &:nth-child(1) {
                  font-size: 18px;
                  letter-spacing: 1.5px;
                }
                &:nth-child(2) {
                  font-size: 14px;
                }
              }
            }

            .image {
              width: auto;
              max-width: initial;
              width: 100%;
              text-align: center;
              img {
                max-width: 375px;
              }
            }
          }

          &:nth-child(1) {
            .desc {
              margin: 0 16px 16px;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 1.5px;
              color: #212121;
              text-align: center;

              span {
                font-size: 24px;
                font-weight: bold;
                color: #d91d22;
              }
            }
          }

          &:nth-child(2) {
            margin-bottom: 28px;
            img {
              width: 143px;
            }
          }

          &:nth-child(3) {
            margin-bottom: 32px;
            .image {
              margin: 24px 16px 0 0;
              img {
                width: 130px;
                height: 115px;
              }
            }
          }

          .footer-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .card-button {
              margin-bottom: 16px;
              height: 36px;
              cursor: pointer;
              padding: 6px 40px;
              border-radius: 4px;
              border: none;
              outline: none;
              box-shadow: 0 2px 4px 0 rgba(104, 0, 0, 0.5);
              background-image: linear-gradient(to bottom, #ff6143, #d91d22);
              font-size: 14px;
              line-height: 1.71;
              letter-spacing: 1.31px;
              color: #fff;
            }

            .amount {
              margin-bottom: 8px;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: 1.5px;
              color: #d91d22;
            }

            .disclaimer {
              cursor: pointer;
              text-decoration: underline;
              margin-bottom: 8px;
              font-size: 14px;
              letter-spacing: 1.5px;
            }
          }
        }
      }
    }

    .__react_component_tooltip {
      display: none;
    }
  }
}


@media only screen and (max-width: 500px) {
  #activity .content .bottom-panel.mobile .card:nth-child(3) .content-wrapper {
    .text {
      width: 64%;
    }
    .image {
      width: 36%;
      img {
        width: 122px;
      }
    }
  } 
}
