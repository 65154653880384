#news {
  margin-bottom: 188px;

  .section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24vw;
    margin: 0 auto;
    font-size: 48px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: center;
    color: #00001a;

    &:after {
      margin: 13px 0 56px;
      background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline.png');
      background-size: 451px 8px;
      content: '';
      width: 451px;
      height: 8px;
    }
  }

  .news-carousel-container {
    overflow-x: clip;

    .react-multi-carousel-track {
      .react-multi-carousel-item {
        padding: 0 12px !important;
        .post-container {
          cursor: pointer;
          width: 100%;
          margin-right: 24px;

          img {
            object-fit: cover;
            width: 100%;
            height: 299px;
            margin-bottom: 30px;
          }

          #title {
            margin-bottom: 24px;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            color: #000018;
          }

          #excerpt {
            height: 48px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 16px;
            letter-spacing: 1.5px;
            color: #2b2b2b;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            max-height: 32px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    .react-multiple-carousel__arrow {
      border-radius: 0;
      min-width: 18px;
      min-height: 33px;

      &.react-multiple-carousel__arrow--left {
        left: 0;
      }

      &.react-multiple-carousel__arrow--right {
        right: 0;
      }
    }

    .react-multi-carousel-dot-list {
      display: none;
    }
  }
}

@media only screen and (max-width: 1280px) {
  #news {
    .section-title {
      width: 34vw;
      font-size: 30px;
      font-weight: bold;
      color: #000018;

      &:after {
        margin: 13px 0 40px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #news {
    .news-carousel-container {
      height: auto;
      min-height: 600px;
      .react-multi-carousel-track {
        .post-container {
          width: 100% !important;

          #title {
            width: 100% !important;
            font-size: 20px !important;
            text-align: center;
          }

          #excerpt {
            width: 100% !important;
          }
        }
      }

      .react-multiple-carousel__arrow {
        &.react-multiple-carousel__arrow--right {
          right: 0;
        }
      }

      .react-multi-carousel-dot-list {
        display: flex;
        .react-multi-carousel-dot button {
          margin-right: 16px;
          width: 6px;
          height: 6px;
          border-radius: unset;
          border-width: unset;
          border-style: unset;
          border-color: #999;
          background: #999;
        }
        .react-multi-carousel-dot--active button {
          border-color: #d91d22;
          background: #d91d22;
        }
      }
    }
  }
}
