/*@font-face {*/
/*  font-family: "MicrosoftYaHei";*/
/*  src: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/finpoints/font/MicrosoftYaHei.ttf') format('ttf')*/
/*}*/

/*@font-face {*/
/*  font-family: "Alibaba-PuHuiTi";*/
/*  src: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/Alibaba-PuHuiTi-Regular.ttf') format('ttf'), url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/Alibaba-PuHuiTi-Regular.woff') format('woff'), url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/Alibaba-PuHuiTi-Regular.woff2') format('woff2');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: "Alibaba-PuHuiTiM";*/
/*  src: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/Alibaba-PuHuiTi-Medium.ttf') format('ttf'), url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/Alibaba-PuHuiTi-Medium.woff') format('woff'), url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/Alibaba-PuHuiTi-Medium.woff2') format('woff2');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: "Alibaba-PuHuiTiB";*/
/*  src: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/Alibaba-PuHuiTi-Bold.ttf') format('ttf'), url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/Alibaba-PuHuiTi-Bold.woff') format('woff'), url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/Alibaba-PuHuiTi-Bold.woff2') format('woff2');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: "PingFangSC";*/
/*  src: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/PingFangSCRegular.ttf') format('ttf'), url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/PingFangSCRegular.woff') format('woff'), url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/PingFangSCRegular.woff2') format('woff2');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: "SFCompact";*/
/*  src: url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/sf-compact-text-heavyitalic-webfont.ttf') format('ttf'), url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/sf-compact-text-heavyitalic-webfont.woff') format('woff'), url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/font/sf-compact-text-heavyitalic-webfont.woff2') format('woff2');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

/* Global */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font: normal 16px -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Segoe UI Symbol,Microsoft Yahei;
  width: 100%;
  height: 100%;
  color: #000;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

body, html { overflow-x:hidden; }
