.table-container {
    width: 100%;
    overflow-x: scroll;
}

table {
    border-collapse: collapse;

    thead {
        display: table;
        width: 100%;
        table-layout: fixed;

        tr {
            height: 56px;

            th {
                background-color: #f9f9f9;
                border-collapse: collapse;
                border: none;
            }
        }
    }

    tbody {
        display: block;
        height: 644px;
        overflow-y: scroll;

        tr {
            display: table;
            width: 100%;
            table-layout: fixed;
            height: 128px;
            text-align: center;
            border-bottom: 1px solid #f2f2f2;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .table-container > table {
        width: 200%;
    }

    table {
        overflow-x: scroll;
    }
}
