#user-results {
  margin-bottom:58px;

  .section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 48px;
    padding-bottom: 13px;
    margin: 0 auto 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000018;

    &:after {
      margin: 13px 0 56px;
      background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline.png');
      background-size: 451px 8px;
      content: '';
      width: 451px;
      height: 8px;
    }
  }
  .profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:24px;
    .left{
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 400px;
      .profile_image {
        width: 106px;
        height: 106px;
        border-radius: 100px;
        border: 2px solid #fde8ab;
        display: inline-block;
        margin-right: 24px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 100px;
        }
      }
      .details {
        display: inline-block;
        .account-dropdown {
          cursor: pointer;
          margin-bottom: 8px;
          .react-select__control {
            display: flex;
            width: 265px;
            .react-select__value-container {
              justify-content: flex-start;
              align-items: center;
              padding: 0;
              .react-select__single-value {
                font-size: 28px;
                font-weight: 600;
                color: #000018;
                margin-left: 0;
                margin-right: 0;
                top: 56%;
              }
            }
            .react-select__indicator-separator {
              display: none;
            }
          }
        }

        .group {
          font-size: 12px;
          color: #000018;
        }
      }

    }
    .right {
      .currentRanking {
        font-size: 28px;
        font-weight: 600;
        margin-bottom:8px;
        span {
          color: #cfbc71;
          font-size: 48px;
          font-weight: 900;
          line-height: 0;
          vertical-align: -7px;
          margin-left: 18px;
        }
      }
      .stage {
        font-size: 12px;
        color: #757575;
      }
    }
  }
  .Collapsible {
    box-shadow: 0 0 10px 0 rgba(222, 222, 222, 0.5);
    margin-bottom:31px;
    position: relative;
    .Collapsible__trigger {
      width:100%;
      display: block;
      background:grey;
      padding: 16px 31px 15px 64px;
      background-image: linear-gradient(266deg, #fafafa 99%, #f4f4f4 2%);
      font-size: 24px;
      font-weight: 600;
      cursor:pointer;
      position:relative;
      &:after {
        content:'';
        width:20px;
        height:20px;
        background:url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon-common-more.png') no-repeat center top;
        position:absolute;
        top: 50%;
        left: 25px;
        margin-top: -10px;
      }
      &.is-open {
        &:after {
          transform: rotate(180deg);
        }

      }
    }
    .Collapsible__contentInner {
      padding:27px 63px 25px;
    }
    .ranking {
      font-size: 24px;
      font-weight: 600;
      color: #000018;
      margin-bottom:26px;
      span {
        color: #cfbc71;
        font-size: 32px;
        font-weight: 900;
        display: inline-block;
        vertical-align: -3px;
      }
    }
    .summary {
      .wrapper {
        .status {
          position: absolute;
          right: 31px;
          top: 0;
          width: auto;
          margin-bottom: 0;
          margin-top: 19px;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 1.5px;

          &.in-progress {
            color: #d91d22;
          }

          &.complete {
            color: #000018;
          }
        }
        div {
          margin-bottom:16px;
          width: 50%;
          display: inline-block;
          font {
            min-width:100px;
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 1.69px;
            color: #000018;
            margin-right:24px;
          }
          span {
            font-size: 18px;
            font-weight: 900;
            color: #cfbc71;
          }
        }
      }
    }
  }
  .btn {
    margin-top:25px;
    text-align: center;
    .btnShare, .btnTrade {
      cursor: pointer;
      max-width: 150px;
      width: 100%;
      padding: 12px 0;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1.5px;
      display: inline-block;
      margin: 0 12px;
      border-radius: 4px;
      color: #fff;
    }
    .btnShare {
      background-image: linear-gradient(to bottom, #49bce3, #4b89e1);
    }
    .btnTrade {
      background-image: linear-gradient(110deg, #ff4f24 -50%, #f00 134%);
    }
  }
}

@media only screen and (max-width: 991px) {
  #userResults .Collapsible .summary .wrapper div {
    width:50%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 441px){
  #user-results .Collapsible .summary .wrapper div {
    span {
      display: block;
      margin-top: 5px;
    }
  }
}

@media only screen and (max-width: 767px) {
  #user-results {
    .section-title {
      width: 34vw;
      font-size: 30px;
      font-weight: bold;
      color: #000018;
      padding-bottom: 50px;

      &:after {
        margin: 13px 0 40px;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  #user-results {
    .profile {
      display:initial;
      .left {
        margin-bottom: 24px;
        flex-direction: column;

        .profile_image {
          margin-bottom: 20px;
        }
      }
      .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
      }
    }

    .btn {
      display: flex;

      .btnShare {
        margin: 0 auto !important;
      }

      .btnShare, .btnTrade {
        width: 48%;
      }
    }
  }
}

@media only screen and (max-width: 440px) {
  #user-results {
    .Collapsible {
      .Collapsible__contentInner {
        padding: 27px 25px 25px;
      }
      .summary .wrapper div {
        width:100%;
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  #user-results .btn {
    .btnShare {
      display: block;
      margin: 0 auto 20px;
      max-width: initial;
    }
    .btnTrade {
      max-width: initial;
      margin: 0;
    }
  }
}


