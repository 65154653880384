.redeem-success {
  .popup-wrapper {
    text-align: center;
    .title {
      font-size:28px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .content {
      &.gold {
        width:auto;
        margin: 16px auto 28px;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        color: #f3b33a;
      }
      &.code_title {
        margin-bottom : 12px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #666;
      }
    }
    img.gift {
      max-width: 211px;
      height: 224px;
      margin: 0 auto 13px;
    }
    > .code {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #252525;
      max-width: 312px;
      width:100%;
      margin:auto;
      padding: 12px 27px 11px;
      background-color: #f4f4f4;
      text-align: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .redeem-success {
    .modal {
      height: auto !important;
    }
  }
}
