#prize {
  margin-bottom: 205px;

  &.th {
    .bottom-panel {
      .card-wrapper {
        .content {
          .text {
            div {
              font-size: 12px !important;

              &:first-of-type {
                span {
                  font-size: 0.7vw !important;

                  &:first-of-type {
                    font-size: 16px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .react-tabs .top-panel {
      .card {
        &.winner {
          .text {
            max-width: 194px;
          }
        }
        .text {          
          span:nth-child(1) {
            font-size: 25px;
          }
          span:nth-child(2) {
            font-size: 23px;
          }
        }
      }
    } 
  }
  &.vn {
    .react-tabs .bottom-panel .card .card-wrapper .content .text div:first-of-type span:first-of-type {
      font-size: 22px;
    }
  }
  
  .section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #000018;

    &:after {
      margin: 13px 0 36px;
      background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline.png');
      background-size: 451px 8px;
      content: '';
      width: 451px;
      height: 8px;
    }
  }

  .updated {
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #000018;

    br {
      content: ' ';
      display: none;
    }

    span {
      font-weight: bold;
      color: #ee4000;
    }
  }

  .react-tabs {
    margin-top: 57px;

    .react-tabs__tab-list {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: none;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17.3vw;
        height: 62px;
        text-align: center;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.5px;
        color: #000018;

        &:first-of-type {
          margin-right: 24px;
        }

        &.react-tabs__tab {
          border-radius: 4px;
          box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.18);
          background-color: #f9f9f9;

          &.react-tabs__tab--selected {
            font-size: 20px;
            font-weight: 600 !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.5px;
            color: #ffffff;
            box-shadow: 0 2px 4px 0 rgba(122, 0, 0, 0.5);
            background-image: linear-gradient(to bottom, #ff6143, #d91d22);
            border:0;
          }
        }
      }
    }

    .top-panel {
      margin: 48px 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.mobile {
        display: none;
      }

      .card {
        margin-right: 24px;
        position: relative;
        width: 33%;
        height: 267px;
        background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/others-card.png');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: cover;

        &.winner {
          background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/winner-card.png');
        }

        .text {
          position: absolute;
          top: 20%;
          left: 48px;
          span {
            display: block;

            &:nth-child(1) {
              padding-bottom: 24px;
              font-size: 28px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 2.63px;
              color: #313131;
            }

            &:nth-child(2) {
              padding-bottom: 17px;
              font-size: 42px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 4.5px;

              &.gold {
                color: #cfbc71;
              }

              &.silver {
                color: #a6a5a1;
              }

              &.bronze {
                color: #c58250;
              }
            }

            &:nth-child(3) {
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 1.5px;
              color: #313131;
            }
          }
        }
      }
    }

    .bottom-panel {
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .card {
        position: relative;
        width: 49%;
        height: 162px;
        background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/others-card-small.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .card-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-bottom: 28px;

          .title {
            margin-top: 8px;
            margin-left: 22px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.1;
            letter-spacing: 1.07px;
            color: #000018;
            .icon {
              width: 20px;
              height: 3px;
              margin: 0 9.9px 0 0;
              transform: rotate(-45deg);
              border-radius: 1.5px;
              background-color: #d91d22;
            }
          }
          .content {
            margin: 0 auto;
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            .badge img {
              margin-right: 15px;
              width: 58px;
              height: 62px;
            }
            .text {
              div {
                margin-top: 13px;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1.5px;
                color: #7e7e7e;

                &:first-of-type {
                  margin-top: 0;
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-end;

                  span {
                    margin-bottom: 3px;
                    font-size: 10px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: 0.75px;
                    color: #000018;

                    &:first-of-type {
                      margin-bottom: 0;
                      font-size: 28px;
                      font-weight: bold;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: normal;
                      letter-spacing: 0.88px;
                      color: #363636;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .disclaimer {
      span {
        display: block;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.5px;
        color: #969696;

        &:last-of-type {
          margin-top: 8px;

          span {
            display: inline !important;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  #prize .react-tabs .top-panel .card .text span:nth-child(2) {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1280px) {
  #prize {
    .section-title {
      width: auto;
      font-size: 30px;
      font-weight: bold;
      color: #000018;
    }
  }
}

@media only screen and (max-width: 1170px) {
  #prize {
    .react-tabs {
      .top-panel {
        &.desktop {
          display: none;
        }

        &.mobile {
          display: block;
          margin: 24px 0;

          .react-multi-carousel-track {
            margin-bottom: 27px;
          }
        }
        .card {
          width: 100%;

          .text {
            span {
              font-size: 25px;

              &:last-child {
                font-size: 11px;
              }
            }
          }
        }
      }
      .bottom-panel {
          flex-direction: column;
          .card {
            width: 100%;
          }
      } 
    }

    &.sc, &.tc {
      .top-panel .card .text span{
        font-size: 36px !important;

        &:first-child {
          font-size: 20px !important;
        }

        &:last-child {
          font-size: 16px !important;
        }
      }
    }

    &.en, &.vn {
      .top-panel .card .text span{
        font-size: 25px !important;

        &:first-child {
          font-size: 20px !important;
        }

        &:last-child {
          font-size: 11px !important;
        }
      }
    }

    &.th {
      .top-panel .card .text span{
        font-size: 20px !important;

        &:first-child {
          font-size: 20px !important;
        }

        &:last-child {
          font-size: 11px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  #prize .react-tabs .top-panel {
    .card {
      background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/others-card-new.jpg');
  
      &.winner {
        background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/winner-card-new.jpg');
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #prize.th .react-tabs .top-panel {
    li:first-child {
      .card.winner .text {
        max-width: 160px;
      }
    }
    
  } 
  #prize {
    margin-bottom: 80px;

    .section-title {
      &:after {
        margin: 24px 0 24px;
      }
    }

    .updated {
      font-size: 16px;
      line-height: 1.88;
      letter-spacing: 1.2px;

      br {
        display: block !important;
      }

      span {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1.5px;
      }
    }

    .react-tabs {
      margin-top: 40px;

      .react-tabs__tab-list {
        li {
          padding: 8px 31px;
          width: auto;
        }
      }

      .top-panel {

        .card {
          width: 100%;

          .text {
            left: 5%;
            span {
              &:nth-child(1) {
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 1.5px;
              }

              &:nth-child(2) {
                font-size: 20px;
                letter-spacing: 1.5px;
              }

              &:nth-child(3) {
                font-size: 11px;
              }
            }
          }
        }
      }

      .bottom-panel {
        flex-direction: column;
        .card {
          .title {
            top: 10%;
            font-size: 20px !important;
            line-height: normal;
            letter-spacing: 1.5px;
          }

          .content {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            .text {
              div {
                font-size: 14px !important;
                letter-spacing: 1.31px;

                &:first-of-type {
                  span {
                    font-size: 14px !important;
                    line-height: 1.5;
                    letter-spacing: 1.5px;

                    &:first-of-type {
                      font-size: 20px !important;
                      letter-spacing: 1.5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.th {
      .top-panel {
        .react-multi-carousel-list .prize-carousel-container {
          .card {
            .text {
              span {
                font-size: 20px;

                &:first-child {
                  font-size: 20px;
                }
                &:last-child {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
      .bottom-panel {
        .card-wrapper {
          .content {
            .text {
              div {
                font-size: 12px !important;
                &:first-of-type {
                  span {
                    font-size: 12px !important;
                    &:first-of-type {
                      font-size: 18px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .top-panel {
    .prize-carousel-container {
      height: 300px;

      .react-multi-carousel-dot-list {
        .react-multi-carousel-dot button {
          width: 6px;
          height: 6px;
          margin-right: 16px;
          border-radius: unset;
          border-width: unset;
          border-style: unset;
          border-color: #999;
          background: #999;
        }
        .react-multi-carousel-dot--active button {
          border-color: #d91d22;
          background: #d91d22;
          border: 0;
        }
      }
    }
  }
  .bottom-panel {
    margin-bottom: 16px !important;
    .card {
      &:first-of-type {
        margin-bottom: 16px;
      }

      .title{
        left: 5% !important;
      }

      .content {
        top: 38% !important;
        left: 5% !important;
        transform: none !important;
      }
    }
  }

  .disclaimer span {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 360px) {
  .top-panel {
    .card {
      .text {
        left: 3% !important;
      }
    }
  }
  .bottom-panel {
    .card {
      .title, .content {
        left: 3% !important;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .bottom-panel {
    .card {
      .content {
        top: 28% !important;
        align-items: center !important;
      }
    }
  }
}
