.popupResult {
  text-align: center;
  padding:20px 0;
  &.sc, &.tc {
    //font-family: "Alibaba-PuHuiTi";
    .modal {
      .modal-header h5 {
        font-size: 58px;
        //font-family: "Alibaba-PuHuiTiB";
      }
      //.modal-content {
      //  .stage {
      //    > p {
      //      font-family: "PingFangSC";
      //    }
      //    .place {
      //      font-family: "Alibaba-PuHuiTiB";
      //    }
      //    .yield {
      //      font-family: "PingFangSC";
      //    }
      //  }
      //  .date {
      //    font-family: "Alibaba-PuHuiTi";
      //  }
      //  .member {
      //    .name {
      //      font-family: "PingFangSC";
      //    }
      //  }
      //  .qrcode .scan {
      //    font-family: "PingFangSC";
      //  }
      //}
      //.modal-footer {
      //  font-family: "PingFangSC";
      //}
    }

    //.border {
    //  font-family: "Alibaba-PuHuiTiM";
    //}
  }
  &.th .modal .modal-content .stage .place {
    font-size: 36px;
  }
  &.vn .modal .modal-content .stage .place {
    font-size: 42px;
  }
  .border {
    position:relative;
    padding:2px 0;
    &:before, &:after {
      content:'';
      width:100%;
      height:1px;
      position:absolute;
      left:0;
      opacity: 0.3;
      background-image: linear-gradient(to right, rgba(238, 238, 238, 0), #fff 49%, rgba(216, 216, 216, 0) 100%);
    }
    &:before {top:0;}
    &:after {bottom:0;}
  }
  .modal_wrapper {
    overflow-y: auto;
    max-width: 490px;
    width: 100%;
    margin: auto;
    &::-webkit-scrollbar-track{
      -webkit-box-shadow: inset 0 0 6px rgba(49, 49, 49, 0.3);
      background-color: #181e22;
    }
    &::-webkit-scrollbar{
      width: 6px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb{
      background-color: #902e36;
    }
  }
  .modal {
    height:1262px;
    padding: 32px 0px 0;
    overflow: hidden;
    max-width:initial;
    #user-results-wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      #background {
        width: 100%;
        height: 1262px;
        object-fit: cover;
      }
      .content-wrapper {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
      }
    }
    &.hide {
      display:none !important;
    }
    .close {
      cursor:pointer;
    }
    .modal-header {
      margin-top: 32px;
      margin-bottom: 18px;
      img {
        margin-bottom: 32px;
      }
      h5 {
        font-size: 33px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -3px;
      }
      > p {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
    .modal-content {
      padding: 10px 20px 10px;
      > p {
        margin-bottom:17px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
      .details {
        padding: 16px;
        margin-bottom:40px;
        border-radius: 8px;
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
        border: solid 2px #fff;
        background-color: rgba(253, 253, 253, 0.1);
      }
      .whitebg {
        background:#fdfdfd;
        padding:20px 17px 18px;
        border: solid 1px #bdb2ab;
      }
      .member {
        display:flex;
        align-items: center;
        justify-content: center;
        margin-bottom:18px;
        .image {
          padding: 2px;
          //background: linear-gradient(135deg, #fde8ab, #e4b24f 51%, #fde8ab);
          border-radius: 50px;
          margin-right:20px;
          height: 59px;
          img {
            width: 55px;
            height: 55px;
            border-radius: 50px;
          }
        }
      }
      //.stage_scroll {overflow-y:auto;margin-bottom: 8px;}
      .stage_scroll {margin-bottom: 8px;}
      //.stage_wrapper {height:511px;}
      .stage {
        padding:21px 16px 24px;
        margin-bottom:16px;
        background:#2a2a2a;
        border-radius: 4px;
        position:relative;
        &:before {
          content:'';
          width:54%;
          height:100%;
          position: absolute;
          left: 14%;
          top: 0;
          opacity: 0.25;
          background-image: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0));
          transform: skew(-25deg);
        }
        &.last {
          padding:36px 16px 35px;
          > p {
            margin-bottom: 7px;
          }
          .place {
            font-size: 64px;
          }
        }
        &.mb8 {
          margin-bottom:8px;
        }
        > p {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fddb96;
          margin-bottom: 12px;
        }
        .place {
          font-size: 56px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          //line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: #fbc765;
          //background-image: linear-gradient(113deg, #fee5b0 -1%, #fbc765 98%);
          //-webkit-background-clip: text;
          //background-clip: text;
          //-webkit-text-fill-color: transparent;
          margin-bottom: 10px;
          line-height:56px;
          display: flex;
          justify-content: center;
          &.percentage {
            //font-family: "SFCompact";
            margin-bottom: 0px;
          }
        }
        .yield {
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fddb96;
          span {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.72;
            letter-spacing: normal;
            color: #fddb96;
            //font-family: "SFCompact";
          }
        }
      }
      .date {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #915d1c;
      }
      .bonus {
        margin-bottom:32px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        div {
          display:inline-block;
          color: #fbc765;
          //background-image: linear-gradient(107deg, #fee5b0 1%, #fbc765 97%);
          //font-family: "SFCompact";
          font-size: 31px;
          font-weight: 900;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          //-webkit-background-clip: text;
          //background-clip: text;
          //-webkit-text-fill-color: transparent;
          margin-left:5px;
          margin-right:5px;
          span {
            font-size: 13px;
            //-webkit-background-clip: text;
            //background-clip: text;
            //-webkit-text-fill-color: transparent;
          }
        }
      }
      .qrcode {
        display:flex;
        align-items: center;
        justify-content: center;
        .wrapper {
          background:#fff;
          padding:5px;
          margin-right:17px;
          max-height:104px;
          img {
            max-width: 94px;
          }
          div {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #050100;
          }
        }
        .scan {
          color:#fff;
          text-align: left;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff;
        }
      }
    }
    .modal-footer {
      width:100%;
      padding: 0 19px 12px;
      position: absolute;
      left: 0;
      bottom: 0;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #bababa;
      border-radius: 10px;
    }
  }
  .title {
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}

@media only screen and (max-height: 1300px) {
  .popupResult {
    .modal_wrapper {
      height: -webkit-fill-available;
    }
  }
}

@media only screen and (max-width: 460px) {
  .popupResult {
    &.en .modal .modal-content .stage:not(:nth-child(3)) .place {
      font-size:40px;
    }
    &.sc, &.tc {
      .modal .modal-content .stage:not(:nth-child(3)) .place {
        font-size: 40px;
      }
    }    
    &.th .modal .modal-content .stage:not(:nth-child(3)) .place {
    font-size: 26px;
    }
    &.vn .modal .modal-content .stage:not(:nth-child(3)) .place {
    font-size: 33px;
    }
  }
}
