#terms {
  margin-bottom: 114px;

  ul {
    list-style: none;
  }

  .section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #000018;

    &:after {
      margin: 13px 0 36px;
      background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline.png');
      background-size: 451px 8px;
      content: '';
      width: 451px;
      height: 8px;
    }
  }

  .react-tabs__tab {
    padding: 0 0 16px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1.5px;
    color: #000018;
  }

  .react-tabs__tab-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: none;
    width: 23vw;
    margin: 0 auto;
    padding-bottom: 40px;
  }

  .react-tabs__tab--selected {
    margin-bottom: -2px;
    border-color: unset;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-bottom: 2px solid #ec3f33;
    border-radius: unset;
    font-weight: bold;
    color: #ec3f33;
  }
  .Collapsible {
    .Collapsible__trigger {
      padding: 24px 0;
      border:1px solid #f2f2f2;
      border-left:0;
      border-right:0;
      display:block;
      font-size:16px;
      font-weight: bold;
      line-height:24px;
      letter-spacing:1.5px;
      position:relative;
      cursor:pointer;
      &:after {
        display: none;
        content: '';
        width: 20px;
        height: 20px;
        background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon-common-more.png') no-repeat center top;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
      }
      &.is-open {
        &:after {
          transform: rotate(180deg);
        }
        
      }
    }
    .Collapsible__contentInner {
      padding: 24px 0;

      a {
        color: inherit;
      }
      
    }
    table {
      border: none;
      border-collapse: collapse;
      max-width: 780px;
      width: 100%;
      margin-bottom: 16px;
      td {
        //border: 1px solid black;
        vertical-align: top;
        width: 33.3%;
      }
      tbody {
        display: table-row-group;
        height: auto;
        tr {
          border-bottom: 1px solid transparent;
          text-align: left;
          display: table-row;
          height: auto;
        }
      }
    } 
  }
  ol.first {
    padding-left: 31px;
    &.between {
      display:inline-block;
      > li {
        float: left;
        &:first-child {
          margin-right: 84px;
        }
      }
      
      ul {
        span {
          padding-left: 15px;
        }
      }
    }
    &.border {
      padding: 24px 0 24px 25px;
      border-top:1px solid #f2f2f2;
      border-bottom:1px solid #f2f2f2;
    }
    > li {
      padding-left: 15px;
      
    }
    ul {
      margin-top: 16px;
    }
    li {
      font-size:16px;
      line-height:24px;
      letter-spacing:1.5px;
      &:not(:last-child) {
        margin-bottom:16px;
      }
    }
    > p {
      &:last-child {
        margin-bottom:0;
      }
    }
    p {
      font-size:16px;
      line-height:24px;
      letter-spacing:1.5px;
      margin-bottom:16px;
      &:first-child {
        margin-top:16px;
      }
    }
  } 
}

@media only screen and (max-width: 1280px) {
  #terms {
    .section-title {
      font-size: 30px;
      font-weight: bold;
      color: #000018;

      &:after {
        margin: 13px 0 40px;
      }
    }

    .react-tabs__tab-list {
      width: 100%;
    }
  }
}

//@media only screen and (min-width: 768px) {
//  .react-tabs__tab-panel--selected {
//    .Collapsible__contentOuter {
//      height:auto !important;
//    }
//  }
//}

@media only screen and (max-width: 767px) {
  #terms .Collapsible {
    .Collapsible__trigger {
      &:after {
        display: block;
      }
    }

    .Collapsible__contentInner > ol {
      li, p {
        font-size: 14px;
        letter-spacing: 1.31px;
      }
    }
  }
}
