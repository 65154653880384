#guide {
  margin-bottom: 160px;

  .section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #00001a;

    &:after {
      margin: 13px 0 56px;
      background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/underline.png');
      background-size: 451px 8px;
      content: '';
      width: 451px;
      height: 8px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &.mobile {
      display: none;
    }

    img {
      margin-bottom: 22px;
    }

    .card {
      width: 22%;
      text-align: center;

      span {
        margin-top: 24px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.5px;
        &:first-of-type {
          //font-size: 1.9vw;
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 1.5px;
          color: #000019;
        }
        &:last-of-type {
          display: block;
          //font-size: 1.2vw;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 1.5px;
          text-align: center;
          color: #000;
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  #guide {
    .section-title {
      font-size: 30px;
      font-weight: bold;
      color: #000018;
    }
  }
}

@media only screen and (max-width: 767px) {
  #guide {
    margin-bottom: 80px;
    .section-title {
      width: auto;
      font-size: 30px;
      font-weight: bold;
      color: #000018;

      &:after {
        margin: 24px 0 24px;
      }
    }

    .content {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;

        .guide-carousel-container {
          height: 319px;

          .react-multi-carousel-track {
            .card {
              width: 100%;

              span {
                &:first-of-type {
                  font-size: 28px;
                }
                &:last-of-type {
                  margin-top: 8px;
                  font-size: 16px;
                }
              }
            }
          }

          .react-multi-carousel-dot-list {
            .react-multi-carousel-dot button {
              width: 6px;
              height: 6px;
              margin-right: 16px;
              border-radius: unset;
              border-width: unset;
              border-style: unset;
              border-color: #999;
              background: #999;
            }
            .react-multi-carousel-dot--active button {
              border-color: #d91d22;
              background: #d91d22;
            }
          }
        }
      }
    }
  }
}
