.crm-register {
  flex-direction: column;
  .countdown {
    margin-bottom: 49px;
    .text {
      margin-bottom: 32px;
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
  }
  .flip-countdown {
    height: 60px;
    display: flex;
    justify-content: center;

    .flip-countdown-piece {
        margin: 0;

        &:not(last-of-type) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;

            .flip-countdown-title {
                margin: 0 24px 0;
                display: block;
                font-size: 32px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
            }
        }
        &:not(:first-child){
          .flip-countdown-title {
            margin: 0 24px 11px;
          }
        }

        &:last-of-type {
            .flip-countdown-title {
                display: none;
            }
        }

        .flip-countdown-card {
            height: 100%;
            .flip-countdown-card-sec {
                border-radius: 3.5px;
                margin: 0 2px !important;
                font-size: 42px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
                color: #000000;

                .card__top,
                .card__bottom,
                .card__back,
                .card__back:before,
                .card__back:after {
                  color: #000000 !important;
                }

                .card__top,
                .card__bottom,
                .card__back:before,
                .card__back:after {
                  height: 0.68em;
                }

                .card__top {
                  line-height: normal;
                }

                .card__back:before {
                  padding: 0.15em 0.1em;
                }

                .card__bottom:after {
                  margin-top: -0.54em;
                }

                span {
                    width: 45px;
                }
            }
        }
    }
  }
  .disclaimer {
    display: none;
  }

  .modal {
    padding: 48px 20px 80px;
    .modal-header {
      height: 100%;

      .popup-wrapper {
        height: 100%;

        .title {
          margin-left: 24px;
          font-size: 32px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #252525;
        }

        .content {
          margin-left: 24px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #252525;

          span {
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            color: #e72e2e;
          }
        }

        div {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .crm-register {
    .modal {
      height: 50vh;
    }
  }
  .crm-register .current-count-container {
    .flip-countdown {
      height: auto;
      .flip-countdown-piece {
        .flip-countdown-card {
          .flip-countdown-card-sec {
            font-size: 24px !important;
  
            .card__bottom:after {
              margin-top: -0.52em;
            }
  
            span {
              width: 30px;
            }
          }
        }
  
        &:not(last-of-type) {
          .flip-countdown-title {
            margin: 0 8px 0;
            font-size: 16px;
          }
        }
      }
      .flip-countdown-card-sec {
        span {
          width: 25px !important;
        }
      }
      .react-multi-carousel-list {
        height: auto;
        .banner-container.banner-2 .banner-text {
          
          #title {
            padding-bottom:0;
          }
          #desc {
            margin: 20px 0 330px;
          }
        }
      } 
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .crm-register {
    .modal {
      height: 80vh;
    }
  }
}

@media screen and (max-width: 767px) {
  .crm-register {
    .countdown {
      margin-bottom: 20px;
      .text {
        margin-bottom: 15px;
      }
      .flip-countdown-card-sec {
        span {
          width: 30px !important;
        }
      }
    }

    .modal {
      height: 100vh;
    }
  }
}

@media only screen and (max-width: 360px) {
  .crm-register {
    .countdown {
      .flip-countdown-card-sec {
        span {
          width: 25px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .crm-register {
    .countdown {
      .flip-countdown-card-sec {
        span {
          width: 20px !important;
        }
      }
    }
  }
}
