.banner {
  margin-bottom: 120px;

  .custom-dot-list {
    display: none;
    bottom: 20px;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .banner-container {
    position: relative;
    width: 100%;
    height: 1070px;
    //background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/home-banner-1.png') no-repeat center;

    .banner-text {
      position: absolute;
      top: 25%;
      left: 13%;

      #title {
        padding-bottom: 27px;
        font-size: 48px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        color: #fff;
      }

      #duration {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 1.31px;
        color: #fff;

        span {
          display: block;
          padding-bottom: 8px;

          &:last-of-type {
            padding-bottom: 48px;
          }
        }
      }

      #desc {
        margin: 24px 0 48px;
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 5.56px;
        color: #fff;
      }
    }
    &.banner-0 {
      background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/S2-Trading-Website-3.0-Banner-Web-KeyVisual_new2.jpg') no-repeat center;
      background-size: cover;
      #title {
        font-size: 48px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        color: #fff;
        padding-bottom: 36px;
        span {
          font-size: 28px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.88px;
          color: #fff;
          display:block;

        }
      }
      .group {
        margin-bottom:25px;
        h4 {
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.88px;
          color: #fff;
          margin-bottom:16px;
        }
        p {
          font-size: 22px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.69px;
          color: #fff;
        }
        img {
          display: inline-block;
          vertical-align: -9px;
        }
      }
      .banner-button {
        margin-top:11px;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 1.88px;
        text-align: center;
        color: #fff;
        padding:19px 16px;
        min-width:180px;
      }
    }
    &.banner-2 {
      background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/home-banner-2-desktop-new01.png') no-repeat center;
      background-size: cover;

      .banner-text {
        top: 25%;
        #title {
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;

          span {
            font-size: 35px;
            font-weight: normal;
            letter-spacing: 8px;
            &.number {
              font-size: 65px;
              font-style: italic;
              font-weight: bold;
            }
            &:last-of-type {
              font-size: 40px;
              letter-spacing: 5.33px;
            }
          }
        }

        #desc {
          margin: 24px 0 48px;
          font-size: 30px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 5.56px;
          color: #fff;
        }
      }

      .banner-button {
        border-radius: 4px;
        background-color: #d91d22 !important;
        background-image: unset;
        border: 0;
      }
    }

    &.banner-3 {
      background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/home-banner-2-desktop-new.png') no-repeat center;
    }

    .banner-button {
      height: 64px;
      background-image: linear-gradient(121deg, #ffc9b6 -52%, #ff4431 80%);
      box-shadow: 0 2px 4px 0 rgba(104, 0, 0, 0.5);
      padding: 20px 46px;
      border: none;
      cursor: pointer;
      border-radius: 6px;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 1.88px;
      text-align: center;
      color: #fff;
      border: 0;
    }

    .banner-title {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      font-size: 48px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 3.6px;
      color: #fff;
    }
  }

  .current-count {
    background: #000 url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/banner-current-count.jpg') no-repeat center;
    background-size: cover;
    margin-top: -5px;
    .banner-title {
      font-size: 48px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 3.6px;
      color: #fff;
      text-align: center;
    }

    .current-count-container {
      margin: 0 auto;
      width: 80vw;
      max-width: 1398px;
      padding-bottom: 107px;

      .amount {
        margin-bottom: 33px;

        #total {
          text-align: center;
          font-size: 143px;
          margin-bottom: 53px;
          font-weight: 900;
          background-image: linear-gradient(107deg, #ff4b16 -5%, #ff6941 5%, #d91d22 102%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          span {
            font-style: italic;
          }
        }

        .label {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.mobile {
            display: none;
          }

          span {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
          }
        }
      }

      .box {
        margin-bottom: 80px;
        padding: 27px 32px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.5px;
        color: #fff;
        border-radius: 11px;
        -webkit-backdrop-filter: blur(19px);
        backdrop-filter: blur(19px);
        background-color: rgba(39, 39, 39, 0.46);

        span {
          display: block;
          margin: 16px 0;

          a {
            color: inherit;
            text-decoration: underline;
          }
        }
      }

      .title {
        margin-bottom: 24px;
        text-align: center;
        font-size: 48px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2.57px;
        color: #ec3f33;
      }

      .event-desc {
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }

      .flip-countdown {
        height: 60px;
        display: flex;
        justify-content: center;

        .flip-countdown-piece {
          margin: 0;

          &:not(last-of-type) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;

            .flip-countdown-title {
              margin: 0 24px 0;
              display: block;
              font-size: 32px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: center;
              color: #fff;
            }
          }
          &:not(:first-child){
            .flip-countdown-title {
              margin: 0 24px 11px;
            }
          }

          &:last-of-type {
            .flip-countdown-title {
              display: none;
            }
          }

          .flip-countdown-card {
            height: 100%;
            .flip-countdown-card-sec {
              border-radius: 3.5px;
              margin: 0 2px !important;
              font-size: 42px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: #000000;

              .card__top,
              .card__bottom,
              .card__back,
              .card__back:before,
              .card__back:after {
                color: #000000 !important;
              }

              .card__top,
              .card__bottom,
              .card__back:before,
              .card__back:after {
                height: 0.68em;
              }

              .card__top {
                line-height: normal;
              }

              .card__back:before {
                padding: 0.15em 0.1em;
              }

              .card__bottom:after {
                margin-top: -0.54em;
              }

              span {
                width: 45px;
              }
            }
          }
        }
      }

      .disclaimer {
        padding-top: 25px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 1.31px;
        color: #fff;
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .banner .current-count {
    min-height: 997px;
  }
}

@media only screen and (max-width: 1024px) {
  .navigation.mobile .navigation-container .container-left .logo img {
    max-width: 135px;
  }
  .banner {
    margin-bottom: 60px;
    &.vn {
      .banner-container.banner-2 .banner-text #title span {
        &.number {
          font-size: 29px !important;
        }
        &:last-of-type {
          font-size: 21px;
        }
      }
    }

    .banner-container.banner-1 #title {
      padding-bottom: 0;
    }

    .current-count {
      .current-count-container {
        .amount {
          margin-bottom: 40px;

          #total {
            font-size: 57px;
            margin-bottom: 40px;
          }

          .label {
            &.desktop {
              display: none;
            }

            &.mobile {
              display: flex;
            }

            span {
              font-size: 12px;
              font-weight: 600;
              line-height: 1.5;
              letter-spacing: 1.5px;
            }
          }
        }
        .box {
          padding: 24px 16px;
        }

        .title {
          font-size: 30px;
          letter-spacing: 1.5px;
        }

        .event-desc {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: 1px;
        }

        .flip-countdown {
          height: auto;
          .flip-countdown-piece {
            .flip-countdown-card {
              .flip-countdown-card-sec {
                font-size: 24px !important;

                .card__bottom:after {
                  margin-top: -0.52em;
                }

                span {
                  width: 30px;
                }
              }
            }

            &:not(last-of-type) {
              .flip-countdown-title {
                margin: 0 8px 0;
                font-size: 16px;
              }
            }
            &:not(:first-child){
              .flip-countdown-title {
                margin: 0 12px 4px;
              }
            }
          }
        }
        .disclaimer {
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: 1.5px;
        }
      }
      .banner-title {
        font-size: 16px;
        padding-top: 44px;
        padding-bottom: 16px;
      }
    }

    .banner-container {
      height:700px;
      &.banner-0 {
        background: #0a090a url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/S2-Trading-Banner-MobileKeyVisual-.jpg') no-repeat center;
        background-size: cover;
        #title {
          font-size: 28px !important;
          span {
            font-size: 18px;
          }
        }
        .group {
          margin-bottom:25px;
          h4 {
            font-size: 18px;
            display:block;
            text-align: center;
            margin-bottom: 0px;
          }
          p {
            font-size: 14px;
          }
          img {
            height: auto;
            width: 24px;
          }
        }
        .banner-button {
          font-size: 14px;
          padding: 10px 24px;
          line-height: 1.71;
          letter-spacing: 1.31px;
          height: auto;
          min-width: initial;
        }
      }
      &.banner-1 {
        //background:#0a090a url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/mobile-home-banner-1.png') no-repeat center;
        .banner-text {
          top:initial !important;
          bottom: 0;
          transform: translate(-50%, -15%) !important;

          #duration {
            text-align: left;
            max-width: fit-content;
            margin: 0 auto;
          }

          #desc {
            font-size: 14px;
          }
        }
      }
      &.banner-2 {
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/home-banner-2-desktop-new-2.png') no-repeat center !important;
        height: 700px;
        background-size: cover !important;
        .banner-text {
          top: 49%;
          #title {
            font-size: 20px;

            span {
              font-size: 20px;
              letter-spacing: normal !important;

              &.number {
                font-size: 35px;
                font-style: normal;
              }
              &:last-of-type {
                font-size: 20px;
              }
            }
          }
          #desc {
            margin: 20px 0 100px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.6px;
            color: #fff;
          }
        }
      }
      .banner-title {
        display: none;
      }
      .banner-text {
        width: 100%;
        padding: 0 16px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;

        #title {
          font-size: 30px;
          text-align: center;
          color: rgba(255, 255, 255, 0.95);
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        }

        #duration {
          font-size: 14px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: 1.31px;
          color: rgba(255, 255, 255, 0.95);
        }
      }

      .banner-button {
        padding: 10px 24px;
        top: unset;
        bottom: 0;
        left: 50%;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 1.31px;
        color: #fff;
        height: auto;
        background: #d91d22;
        border: 0;
      }
    }

    .flip-countdown-card-sec {
      span {
        width: 25px !important;
      }
    }
    .react-multi-carousel-list {
      height: auto;
      .banner-container.banner-2 .banner-text {

        #title {
          padding-bottom:0;
        }
        #desc {
          margin: 20px 0 330px;
        }
      }
    }
  }
}

@media only screen and (max-width: 426px) {
  .banner {
    &.en, &.th, &.vn {
      .react-multi-carousel-list .banner-container.banner-1 .banner-text #title {
        font-size: 22px !important;
      }
    }
    .banner-container {
      &.banner-1 {
        background: #0a090a url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/home-banner-new.jpg') no-repeat center;
        background-size: 1000px auto;

        #duration {
          text-align: left;
          span:last-of-type {
            padding-bottom:13px !important;
          }
        }
      }
    }
    .current-count {
      .current-count-container {
        padding: 0 20px 40px;
        width: 100%;

        .banner-title {
          margin: 5px 0 16px;
          display: block;
          text-align: center;
          font-weight: bold;
          color: #fff;
        }

        .box {
          margin-bottom: 32px;
          span {
            &:first-of-type {
              margin: 0 0 16px;
            }
            &:last-of-type {
              margin: 16px 0 0;
            }
          }
        }

        .event-desc {
          margin-bottom: 40px;
        }

        //.flip-countdown {
        //  justify-content: space-between;
        //}
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .banner .current-count .current-count-container .flip-countdown .flip-countdown-piece:not(last-of-type) .flip-countdown-title {
    margin: 0 4px 0;

    .flip-countdown-card-sec {
      span {
        width: 20px !important;
      }
    }
  }
}
