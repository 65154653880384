.redeem-form {
  text-align: left;
  .title {
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252525;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252525;
  }
  form {
    margin: 32px 0 42px 0;

    .error {
      position: absolute;
      z-index: 1;
      font-size: 13px;
      left: 10px;
      top: -8px;
      background: #fff;
      padding: 0 2px;
      color: #de0000;
    }

    .name-text {
      position: relative;

      input {
        font-family: inherit;
        font-size: 14px;
        padding: 10px 10px;
        margin-bottom: 42px;
        width: 100%;
        height: 40px !important;
        border-radius: 2px;
        border: solid 1px #ddd;
        background-color: #fff;
      }
    }
    .react-tel-input {
      margin-bottom: 42px;
      input {
        width: 100%;
        border-radius: 2px;
      }
    }
    .address-select {
      position: relative;
      .province-dropdown  {
        font-size: 14px;
        border: solid 1px #ddd;
        margin-bottom: 42px;
        .react-select__control {
          width: 100%;
          height: 40px;
          display: flex;
          .react-select__value-container {
            .react-select__single-value, .react-select__placeholder {
              top: 60%;
            }
          }
        }
      }
    }
    .address-text {
      position: relative;
      textarea {
        font-family: inherit;
        width: 100%;
        height: 100px;
        margin-bottom: 42px;
        border: solid 1px #ddd;
        font-size: 14px;
        padding: 10px 10px;
      }
    }
    button {
      cursor: pointer;
      width: 100%;
      height: 48px;
      border-radius: 2px;
      border-color: #e72e2e;
      background-color: #e72e2e;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
    }
  }
}
