.popupInvite {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding:20px 0;
  &.sc, &.tc {
    //font-family: "Alibaba-PuHuiTi";
    .modal .modal-header h5 {
      font-size: 58px;
    }
    //.border {
    //  font-family: "Alibaba-PuHuiTiM";
    //}

  }
  &.th {
    .qrcode {
      .wrapper {
        div {
          font-size: 14px !important;
        }
      }
    }
  }
  .border {
    position:relative;
    padding:2px 0;
    &:before, &:after {
      content:'';
      width:100%;
      height:1px;
      position:absolute;
      left:0;
      opacity: 0.3;
      background-image: linear-gradient(to right, rgba(238, 238, 238, 0), #fff 49%, rgba(216, 216, 216, 0) 100%);
    }
    &:before {top:0;}
    &:after {bottom:0;}
  }
  .modal_wrapper {
    position: relative;
    overflow-y: auto;
    max-width: 375px;
    width: 100%;
    margin: auto;
    &::-webkit-scrollbar-track{
      -webkit-box-shadow: inset 0 0 6px rgba(49, 49, 49, 0.3);
      background-color: #181e22;
    }
    &::-webkit-scrollbar{
      width: 6px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb{
      background-color: #902e36;
    }
  }
  .modal {
    height:812px;
    background-color: transparent;
    padding: 0;
    overflow: hidden;
    max-width:initial;
    #invite-friend {
      position: relative;
      top: 0;
      #background {
        width: 100%;
        height: 812px;
      }
      .content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
    &.hide {
      display:none !important;
    }
    .close {
      cursor:pointer;
    }
    .modal-header {
      margin-top: 32px;
      margin-bottom:26px;
      img {
        margin-bottom: 36px;
      }
      h5 {
        font-size: 33px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -3px;
      }
      > p {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
    .modal-content {
      padding: 10px 20px 10px;
      > p {
        margin-bottom:17px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
      .bonus {
        margin-bottom:71px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        div {
          display:inline-block;
          color: #fbc765;
          //background-image: linear-gradient(107deg, #fee5b0 1%, #fbc765 97%);
          //font-family: "SFCompact";
          font-size: 31px;
          font-weight: 900;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          //-webkit-background-clip: text;
          //background-clip: text;
          //-webkit-text-fill-color: transparent;
          margin-left:5px;
          margin-right:5px;
          span {
            font-size: 13px;
            //-webkit-background-clip: text;
            //background-clip: text;
            //-webkit-text-fill-color: transparent;
          }
        }
      }
      .qrcode {
        padding: 16px;
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
        border: solid 2px #fff;
        background-color: rgba(25, 25, 25, 0.14);
        max-width:220px;
        margin:auto;
        position: absolute;
        left: 50%;
        bottom: 166px;
        margin-left: -110px;
        .wrapper {
          background:#fff;
          padding:18px 22px 11px;
          img {
            max-width: 142px;
            margin-bottom:20px;
          }
          div {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #050100;
          }
        }
      }
    }
    .modal-footer {
      flex-direction: column;
      justify-content: end;
      padding: 0 19px 12px;
      min-height: 86px;
      background-image: linear-gradient(to bottom, rgba(46, 46, 46, 0) -7%, #151515 57%);
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color:#fff;
      border-radius: 10px;
    }
  }
  .title {
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}

@media only screen and (max-width: 1430px) {
  .popupInvite {
    .modal_wrapper {
      height: -webkit-fill-available;
    }
  }
}
