.navigation {
  &.desktop {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    .navigation-container {
      position: fixed;
      width: 100vw;
      display: flex;
      justify-content: space-around;
      align-items: center;
      z-index: 10;
      padding: 37px 0 25px;
      background-color: transparent;

      &.colour-change {
        background-color: #000000;
      }
    }

    .logo {
      display: inline-flex;
      justify-content: center;
      width: 18%;

      a {
        text-decoration: none;
      }
    }

    .menu {
      display: inline-flex;
      max-width: 835px;
      width: 100%;

      ul {
        display: flex;
        list-style: none;
        justify-content: space-around;
        width: 100%;

        li {
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 1.5px;
          color: #ffffff;

          a {
            color: #ffffff;

            &:hover {
              color: #ff3333;
            }
          }

          &:hover {
            color: #ff3333;
          }
        }
      }
    }

    .side {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18%;

      .greeting {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.5px;
        color: #ffffff;

        &.logout {
          cursor: pointer;
          margin-left: 16px;
        }
      }

      .login-button {
        cursor: pointer;
        padding: 12px 35px;
        border-radius: 6px;
        background-color: #ec3f33;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.5px;
        color: #fff;
        border: 0;
      }

      .react-select__menu {
        margin-top: 0;
        margin-bottom: 0;
      }

      .react-select__menu-list {
        max-height: unset;
        padding-top: 0;
        padding-bottom: 0;
      }

      .language-dropdown {
        cursor: pointer;
        .react-select__control {
          width: 120px;

          .react-select__value-container {
            justify-content: center;
            align-items: center;
            .react-select__single-value {
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              line-height: 1.5;
              letter-spacing: 1.5px;
              color: #ffffff;
              margin-left: 0;
              margin-right: 0;
              top: 54%;
            }
          }

          .react-select__indicators {
            display: none;
          }
        }
      }
    }
  }

  &.mobile {
    display: none;

    .navigation-container {
      position: fixed;
      width: 100vw;
      z-index: 10;
      height: 80px;
      padding: 22px 16px;
      display: flex;
      justify-content: space-between;
      background-color: transparent;

      &.colour-change {
        background-color: #ffffff;

        .container-left {
          .hamburger {
            .burger {
              background-color: #000000;
            }
          }
          .logo {
            img {
              content: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/dooprime-logo-mobile.svg');
            }
          }
        }

        .container-right {
          .login {
            .greeting {
              color: #000000;
              &.logout {
                color: #000000;
              }
            }
          }
        }
      }

      .container-left {
        width: 70%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .hamburger {
          margin-right: 17px;
          width: 20.48px;
          height: 24px;
          display: flex;
          justify-content: space-around;
          flex-flow: column nowrap;
          z-index: 10;

          .burger {
            width: 20.48px;
            max-width: 36px;
            height: 2px;
            border-radius: 10px;
            background-color: #ffffff;
            transform-origin: 3px;
            transition: all 0.3s linear;
          }
        }

        .logo {
          a {
            text-decoration: none;
          }
          img {
            height: 32px;
          }
        }
      }

      .container-right {
        width: 30%;
        .login {
          .greeting {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #fff;
            

            &.logout {
              cursor: pointer;
              margin-left: 16px;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: right;
              color: #fff;
            }
          }
          .open-account {
            cursor: pointer;
            height: 36px;
            padding: 8px 12px;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            background-color: red;
            color: rgba(255, 255, 255, 0.95);
            border: 0;
          }
        }
      }
    }

    .hamburger {
      &.hamburger-open {
        display: inline;
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 644px;
        background-color: #ffffff;
        z-index: 20;
      }

      &.hamburger-close {
        display: none;
      }

      .hamburger-top {
        padding: 0 20px 0;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 2px 4px 0 rgba(235, 235, 235, 0.5);

        a {
          text-decoration: none;
        }

        img {
          cursor: pointer;
        }
      }

      .hamburger-menu {
        ul {
          li {
            cursor: pointer;
            padding: 0 20px 0;
            display: flex;
            align-items: center;
            list-style-type: none;
            height: 60px;
            border-bottom: 1px solid #e4e4e4;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.5px;
            color: rgba(0, 0, 0, 0.95);

            &:last-of-type {
              display: block;
              padding: 18px 0 18px;

              .Collapsible {
                padding: 0 20px 0;
                width: 100%;
                background-color: #ffffff;
                .Collapsible__trigger {
                  display: block;
                  position: relative;
                  cursor:pointer;
                  &:after {
                    content:'';
                    width: 20px;
                    height: 20px;
                    background:url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon-common-more.png') no-repeat center top;
                    position:absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -10px;
                  }
                  &.is-open {
                    &:after {
                      transform: rotate(180deg);
                    }
                  }
                }
                .Collapsible__contentInner {
                  padding: 16px 0 24px;

                  > ol {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 40px;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: 1.5px;
                    color: #969696;

                    img {
                      display: none;
                      width: 24px;
                      height: 24px;
                    }

                    &.selected {
                      color: rgba(0, 0, 0, 0.95);

                      img {
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .hamburger-buttons {
          margin: 240px 20px 40px;
          display: flex;
          justify-content: space-between;

          div {
            width: 48%;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.login-button {
              color: #d91d22;
              border-radius: 4px;
              border: solid 1px #e4e4e4;
              background-color: #fff;
              border: 0;
            }
            &.signup-button {
              color: #fff;
              box-shadow: 0 2px 4px 0 rgba(104, 0, 0, 0.5);
              background-color: #d91d22;
              border: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .navigation {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }
  }
}
