.bar-contain {
    width: 100% !important;
    height: 24px !important;
    margin-bottom: 26px !important;
    background-color: #fdf9f9 !important;
}

.bar-expand {
    height: 24px !important;
    background-image: linear-gradient(to right, #fd6740, #d91d22) !important;
    display:block !important;
}

@media only screen and (max-width: 1280px) {
    .bar-contain {
        height: 16px !important;
        margin-bottom: 16px !important;
    }

    .bar-expand {
        height: 16px !important;
    }
}
