$cdn: "https://cdn.dootech.io/prime-website2/images/";
$Colord22: #d91d22;
$Color026: #de2026;


// for footer use only
html {
  font-size: 53.5715%;
}

.alignCenter {text-align: center;}
  
.footer {
    z-index: 5;
    background-color: #0a0a20;
    position: relative;
    &:after {
      content: "";
      height: 4rem;
      width: 100%;
      background-color: #14142a;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    .container {
      max-width: 1381px;
      width: auto;
      margin: auto;
    }
    .complianceTitle {
      color:rgba(255, 255, 255, 0.95);
      margin-bottom: 20px;
      font-size:14px;
      font-weight:600;
      font-style: italic;
      padding-top:16px;
      margin-top:1.6rem;
      border-top:1px solid #fff;
    }
    .compliance_desc {
        padding-bottom:0;
        margin-bottom:0;
    }
    .collapsible {cursor:pointer;}
    .complianceWrapper {
      li {
        border-top: 1px solid rgba(255, 255, 255, 0.15);
      }
      .content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
      }
      .Collapsible {
        .Collapsible__trigger {
          color:rgba(255, 255, 255, 0.95);
          font-size:12px;
          font-weight:600;
          margin-bottom: 0px !important;
          padding: 16px 30px 16px 0;
          position: relative;
          display: block;
          cursor:pointer;
          &:last-child {
            padding-bottom:0;
          }
          &:after {
            content: '';
            background:url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon-common-more.png') no-repeat center top;
            height: 14px;
            width: 14px;
            float: right;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -7px;
            filter: brightness(0) invert(1);
            transform: rotate(-90deg);
            background-size: contain;
          }
          &.is-open {
            &:after {
              content: '';
              -ms-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
          }
        }
      }
    }
    .clocation-wrapper {
      font-size:12px;
      color: #16a0ff;
      display:none;
      visibility: visible !important;
      margin-top: -12px;
    }
  }

  .footer-content {
    z-index: 10;
    position: relative;
    .white-bg {
      background: #fff;
      min-height: 11.6rem;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    h5 {
      font-size: 16px;
      line-height: 1.56;
      letter-spacing: 0.16px;
      margin-bottom: 24px;
    }
    p {
      color: rgba(255, 255, 255, 0.37);
      margin-bottom: 2rem;
    }
    .white_bg {
      background: #fff;
      min-height: 11.6rem;
      .container {
        > img {
          display: inline-block;
          vertical-align: top;
          margin: 0;
          padding: 0;
          margin-right: 1.5rem;
        }
      }
      .advantage {
        font-size: 1.8rem;
        color: #131329;
        margin-right: 4rem;
        display: inline-block;
        padding-top: 2.4rem;
        text-transform: capitalize;
        vertical-align: top;
        i {
          font-size: 4.8rem;
          color: $Colord22;
          font-weight: 500;
          display: inline-block;
          vertical-align: -6px;
        }
      }
      .signup_button {
        float: right;
        padding-top: 3rem;
        > a {
          padding: 1.4rem 8.1rem;
          background: $Colord22;
          margin-bottom: 2px;
          display: block;
          text-align: center;
          font-size: 1.6rem;
          font-weight: bold;

          line-height: 1.5;

          color: rgba(255, 255, 255, 0.95);
        }
        p {
          text-align: center;
          color: #131329;
          margin-bottom:0 !important;
          // text-transform: capitalize;
          a {
            color: #000018;
            font-size:16px;
            font-weight:500;
          }
        }
      }
      @media screen and (max-width: 1299px) {
        .signup_button {
          width: 260px;
          font-size: 16px;
          height: 56px;
          padding-top:0;
        }
        .container {
          max-width: fit-content;
          margin: 0 auto;
          // padding: 40px 57px;
          padding-top:40px;
          padding-bottom:120px;
          > img {
            display: none;
          }
        }
        .advantage {
          display: block;
          padding-top: 0;
          padding-bottom: 0;
          font-weight: 500;
          margin-right: 0;
          i {
            font-weight: 600;
            display: block;
          }
        }
        .signup_button {
          float: initial;
          height: fit-content;
          > a {
            padding: 14px 0px;
            margin-top: 32px;
            margin-bottom: 12px;
          }
          p {
            margin-bottom: 0;
            padding-bottom: 0px;
            text-transform: inherit;
            a {
              text-transform: inherit;
            }
          }
        }
      }
    }
    .social {
      padding: 1.1rem 0;
      .brand {
        display: inline-block;
        margin-bottom: 28px;
        img {
          max-width: 166px;
          width: 100%;
        }
      }
      .pull_right {
        a {
          margin-right: 12px;
          display: inline-block;
          padding-top: 8px;
          &:last-child {
            margin-right: 0;
          }
          img {
            max-width: 24px;
            display: inline-block;
            vertical-align: text-bottom;
          }
        }
        .qr_wechat {
          position: relative;
          z-index: 1;
          &:hover,
          :focus {
            &:before {
              content: "";
              width: 110px;
              height: 114px;
              border-radius: 10px;
              border: 4px solid #fff;
              position: absolute;
              right: 0;
              top: 40px;
              background: url("#{$cdn}qr-wechat.png") no-repeat left top;
            }
          }
        }
      }
    }

    .footer_menu {
      background: #2f2f38;
      margin-bottom: 2.9rem;
      border-top: 1px solid #d91d22;
      .container {
        padding:0 15px;
        .doo_select_input {
          margin-right: -2.3rem !important;
        }
      }
      a {
        font-size: 1.4rem;
        line-height: 1.57;
        color: rgba(255, 255, 255, 0.95);
        display: inline-block;
        position: relative;
        text-transform: capitalize;
        padding: 11px 24px 11px 0;
        margin-right: 24px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right:0;
          margin-right:0;
          &:after {
            display: none;
          }
        }
        &:after {
          content: "";
          width: 1px;
          height: 8px;
          background: rgba(255, 255, 255, 0.15);
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -3px;
        }
        &.remove_border {
          &:after {
            display:none;
          }
        }
      }
      .pull_right {
        top: -5px;
        .doo_select_lang {
          .doo_select_input {
            min-width: auto;
          }
          .doo_select_value:before {
            top: -8px;
          }
        }
      }
    }
    .container {
      //font-family: poppins;
      font-size: 1.4rem;
      > h3 {
        //font-family: poppins;
        font-size: 1.6rem;
      }
    }
    .grid {
      text-transform: capitalize;
      grid-gap: 2.5rem;
      h5 {
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 1rem;
        color: rgba(255, 255, 255, 0.95);
      }
      span,
      p {
        margin-bottom: 8px;
        font-weight: 400;
      }
      span {
        font-size: 1.2rem;
        margin-top: 1.6rem;
        margin-bottom: 8px;
        display: block;
        color: #fff;
        padding-left:13px;
        position:relative;
        &:before {
          content:'';
          width:8px;
          height:2px;
          background:#d91d22;
          position:absolute;
          left:0;
          top:50%;
          transform: rotate(135deg);
        }
      }
      p {
        color: #a2a2a2;
      }
    }
    hr {
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
      border: 1px solid rgba(255, 255, 255, 1);
    }
    .bulletin {
      margin-top:40px;
      .card {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        padding-bottom:38px;
        padding-right:90px;
      }
      h3, a {
        color:rgba(255, 255, 255, 0.95);
      }
      h3 {
        margin-bottom:6px;
        font-weight: 400;
      }
      p {
        color:rgba(255, 255, 255, 0.37);
        margin-bottom:7px;
        &:first-letter {
          text-transform: capitalize;
        }
      }
      a {
        display:block;
        text-transform: lowercase;
        margin-bottom:6px;
      }
    }
    .doo_footer_news_card {
      // opacity: 0.45 !important;
      h3 {
        opacity: 0.45 !important;
        font-weight: bold !important;
        &:nth-child(1) {
          padding-bottom: 22px !important;
        }
      }
    }
    .copyright {
      background: #131329;
      padding: 8px 0;
      color: rgba(255, 255, 255, 0.95);
      .container {
        //font-family: poppins;
        font-size: 1.4rem;

        line-height: 1.8;

        color: rgba(255, 255, 255, 0.95);
        text-align: center;
        padding: 0 50px;
      }
    }
}

.topBorder {
  border-top: 1px solid #fff !important;
  .Collapsible {
    .Collapsible__trigger {
      font-size:14px !important;
    }
  }
}

.grid {
  display: grid;
  padding-bottom: 6.6rem;
  grid-gap: 2.5rem;
  &.column3 {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
  }
  &.column4 {
    grid-template-columns: repeat(4, 1fr);
  }
  &.column6 {
    grid-template-columns: repeat(6, 1fr);
  }
  &.column7 {
    grid-template-columns: repeat(7, 1fr);
  }
}

.jurisdiction {
  visibility: visible !important;
  display:none;
}
.jurisdiction_eu {
  visibility: visible !important;
  display:none;
}
.jurisdiction_aus {
  visibility: visible !important;
  display:none;
}
.jurisdiction_uk {
  visibility: visible !important;
  display:none;
}

@media screen and (max-width: 1299px) {
    footer {
        .white_bg {
          .container {
            // padding: 40px 57px;
            padding-top:40px;
            padding-bottom:120px;
          }
          .advantage {
            text-align: center;
            margin-bottom: 10px;
            i {
              display:block;
              line-height: 51px;
            }
          }
          .signup_button {
            > a {
              margin-bottom: 12px;
            }
          }
        }
      }
      .footer-content .footer_menu a {
        &:last-child {
          float:initial;
          padding: 12px 12px 12px 0;
        }
        &.remove_border:after {
          display:block;
        }
      }
}

@media screen and (min-width: 768px){
  .hide_mdlg {display:none !important;}
}

@media screen and (max-width: 767px) {
  .hide_sm {display:none !important;}
  .footer-content {
    .grid {
      grid-template-columns: initial;
      padding-bottom:0;
      .column {
        &:first-child {
          padding-bottom:0;
          .social {
            padding:0;
            a {
                margin-bottom: 16px;
            }
          }
        }
      }
      h5 {
        font-size:16px;
      }
    }
    .social {
      text-align: center;
      .brand {
        img {
          max-width:166px;
        }
      }
    }
    .middle-footer {
      > .container {
        padding: 0px 15px !important;
      }

        .column {
          position:relative;
          padding-bottom: 10px;
          margin-bottom: 10px;
          &.remove_arrow {
            &:before, &:after {
              display:none;
            }
          }
          &:last-child {
            margin-top: 16px;
            padding-bottom: 18px;
            a {
              padding-right:11px;
              img {
                max-width: 2.4rem;
                width:100%;
              }
              &:last-child {
                padding-right:0;
              }
            }
          }
          &:before {
            content: '';
            width:100%;
            height:1px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            position:absolute;
            bottom:0;
            left:0;
          }
          
          span.Collapsible__trigger {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 1rem;
            color: hsla(0,0%,100%,.95);
            line-height: 1.56;
            letter-spacing: .16px;
            display: block;
            position:relative;
            &:after {
              content: '';
              background:url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon-common-more.png') no-repeat center top;
              height: 14px;
              width: 14px;
              float: right;
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -5px;
              filter: brightness(0) invert(1);
              transform: rotate(-90deg);
              background-size: contain;
            }
            &.is-open {
              &:after {
                content: '';
                -ms-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
              }
            }
          }
          &.social_media {
            margin-top: 40px;
            padding-bottom: 22px;
          }
        }
      .content {
        span {
          font-size: 1.2rem;
          margin-top: 1.6rem;
          margin-bottom: 7px;
          display: block;
          color: hsla(0,0%,100%,.37);
        }
        p {
          font-size: 1.6rem;
          color: hsla(0,0%,100%,.95);
          margin-bottom: 5px;
          font-weight: 400;
        }
      }
    }
    .footer_menu {
      margin-bottom: 40px;
      background: #090920;
      padding-top:40px;
      a {
        font-size:14px;
      }
    }
    .white_bg {
      .container {
        padding: 40px 0px;
      }
        .advantage {
            font-size:18px !important;
          }
        .signup_button p a {
            font-size:16px !important;
        }
    }
    .copyright {
      padding: 20px 0;
      margin-top:16px;
    }
  }
  .dooEntity {
    display:block;
    width:100%;
    text-align: center;
    font-size: 16px;
    color:rgba(255, 255, 255, 0.95) !important;
  }
  .footer .complianceWrapper .Collapsible .Collapsible__trigger {
    font-style: normal;
  }
}

@media screen and (max-width: 500px) {
  .footer-content .bulletin {
    margin-top: 0px;
    .card {
      width: 100%;
      padding-right: 0;
    }
  }
}
