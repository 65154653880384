.content-section {
  .content-container {
    margin: 0 auto;
    width: 80vw;
    max-width: 1398px;
  }

  .floating-menu {
    position: fixed;
    top: 25%;
    right: 30px;
    width: 6.6vw;
    height: auto;

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }

    .menu-container {
      border-radius: 17px;
      box-shadow: 0 0 11px 0 rgba(220, 225, 235, 0.5);
      background-color: #fbfbfb;

      .menu {
        cursor: pointer;
        padding: 24px 12px 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #dbdbdb;
        img {
          margin-bottom: 16px;
        }
        span {
          font-size: 1vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.5px;
          text-align: center;
          color: #161616;
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    .scroll-top {
      cursor: pointer;
      margin-top: 35px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
      text-align: center;
      color: #2c2c2c;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .content-section {
    .content-container {
      width: 92vw;
    }

    .floating-menu {
      width: 10vw;
    }
  }
}

@media only screen and (max-width: 767px) {
  .floating-menu {
    display: none !important;
  }
}
